import React from 'react';
import { styled } from '@glitz/react';
import { translate, isBrowser, on } from '@avensia/scope';
import { lightGrey, pixelsToUnit, minMediumMediaQuery, transition } from 'Shared/Style';
import { Down } from 'Shared/Icon/Arrow';
import Check from 'Shared/Icon/Check';
import OrderListProduct from './OrderListProduct';
import { throttle } from 'lodash';
import Link from 'Shared/Link';
import { jade, mineShaft } from 'Shared/Style/colors';
import { formatPrice } from 'Shared/number-format';
import OrderDetailsViewModel from 'Order/Details/OrderDetailsViewModel.type';

type PropType = {
  order: OrderDetailsViewModel;
  isMobile?: boolean;
  culture: string;
};

type StateType = {
  isItemOpen: boolean;
  isProductsOpen: boolean;
  height: string | number;
  mounted: boolean;
};

const TIME_DELAY = 500;

export default class OrderList extends React.Component<PropType, StateType> {
  myOrderDetailProdRef: HTMLDivElement;
  myOrderDetailMobileRef: HTMLDivElement;

  unsubscribeResize: () => void;

  constructor(props: PropType) {
    super(props);

    this.state = {
      isItemOpen: false,
      isProductsOpen: false,
      height: 0,
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ mounted: true }));
    this.updateState();
    this.addListener();
  }

  componentWillUnmount() {
    this.removeListener();
  }

  addListener() {
    if (isBrowser()) {
      this.unsubscribeResize = on(
        'resize',
        throttle(() => {
          if (this.state.mounted) {
            this.updateState();
          }
        }, TIME_DELAY),
      );
    }
  }

  updateState() {
    this.setState((prevState) => ({
      ...prevState,
      height: pixelsToUnit(this.myOrderDetailProdRef.clientHeight),
    }));
  }

  onOpenMobileProductDetail = () => {
    this.setState((prevState) => ({
      ...prevState,
      isProductsOpen: !this.state.isProductsOpen,
      height:
        this.state.isProductsOpen && this.state.isItemOpen
          ? pixelsToUnit(this.myOrderDetailProdRef.clientHeight - this.myOrderDetailMobileRef.clientHeight)
          : pixelsToUnit(this.myOrderDetailProdRef.clientHeight + this.myOrderDetailMobileRef.clientHeight),
    }));
  };

  removeListener() {
    if (this.unsubscribeResize) {
      this.unsubscribeResize();
      this.unsubscribeResize = null;
    }
  }

  render() {
    const { isMobile } = this.props;
    const { isItemOpen, isProductsOpen } = this.state;
    const {
      url,
      shortDateCreated,
      currency,
      totalPrice,
      orderNumber,
      paymentStatus,
      orderStatusDisplay,
      sent,
    } = this.props.order;

    return (
      <React.Fragment>
        <OrderWrapper // tslint:disable-next-line:jsx-no-lambda
          onClick={() => isMobile && this.setState({ isItemOpen: !isItemOpen })}
          css={isMobile && { cursor: 'pointer' }}
        >
          <Order>
            <ShowOrderDetail to={url}>{orderNumber}</ShowOrderDetail>
          </Order>
          <Order>{shortDateCreated}</Order>
          {!isMobile && (
            <>
              <Order>{formatPrice(totalPrice, currency, this.props.culture)}</Order>
              <Order>
                {paymentStatus} <CustomCheckIcon />
              </Order>
              <Order>
                {orderStatusDisplay} {sent && <CustomCheckIcon />}
              </Order>
            </>
          )}
          <ViewOrder
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => !isMobile && this.setState({ isItemOpen: !isItemOpen })}
            css={{ justifyContent: isMobile ? 'flex-end' : 'flex-start' }}
          >
            {!isMobile && <ViewOrderText>{translate('/Account/MyPages/OrderHistory/ViewOrder')}</ViewOrderText>}
            <ArrowPosition css={isItemOpen && { transform: 'rotate(180deg)' }} />
          </ViewOrder>
        </OrderWrapper>

        <OrderDetailsContainer
          css={{
            height: isItemOpen ? this.state.height : 0,
            ...transition({ property: 'height' }),
          }}
        >
          <OrderDetailWrapper
            // tslint:disable-next-line:jsx-no-lambda
            ref={(el: HTMLDivElement) => (this.myOrderDetailProdRef = el)}
          >
            {isMobile ? (
              <MobileHeader>
                <div>
                  {translate('/Account/MyPages/OrderHistory/Ordering')}: {orderNumber}
                </div>
                <div>
                  {translate('/Account/MyPages/OrderHistory/AddedIt')}: {shortDateCreated}
                </div>
                <div>
                  {`${translate('/Account/MyPages/OrderHistory/Sum')}: `}
                  {formatPrice(totalPrice, currency, this.props.culture)}
                </div>
                <div>
                  {translate('/Account/MyPages/OrderHistory/PaymentStatus')}: {paymentStatus}
                </div>
                <div>
                  {translate('/Account/MyPages/OrderHistory/OrderStatus')}: {orderStatusDisplay}
                </div>
                <ViewOrder onClick={this.onOpenMobileProductDetail}>
                  <ViewOrderText>{translate('/Account/MyPages/OrderHistory/ViewOrder')}</ViewOrderText>
                </ViewOrder>
                <styled.Div css={{ height: isProductsOpen ? 'auto' : 0 }}>
                  <OrderDetailWrapper
                    // tslint:disable-next-line:jsx-no-lambda
                    ref={(el: HTMLDivElement) => (this.myOrderDetailMobileRef = el)}
                  >
                    <OrderListProduct
                      products={this.props.order.orderLineItems}
                      orderStatusDisplay={orderStatusDisplay}
                    />
                  </OrderDetailWrapper>
                </styled.Div>
              </MobileHeader>
            ) : (
              <OrderListProduct products={this.props.order.orderLineItems} orderStatusDisplay={orderStatusDisplay} />
            )}
          </OrderDetailWrapper>
        </OrderDetailsContainer>
      </React.Fragment>
    );
  }
}

const CustomCheckIcon = styled(Check, {
  color: jade,
});

const OrderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: {
    x: 0,
    y: pixelsToUnit(10),
  },
});

const Order = styled.div({
  flexBasis: '33%',
  [minMediumMediaQuery]: {
    flexBasis: '16%',
  },
});

const OrderDetailsContainer = styled.div({
  position: 'relative',
  overflow: 'hidden',
  borderBottom: {
    color: lightGrey,
    style: 'solid',
    width: pixelsToUnit(1),
  },
});

const ShowOrderDetail = styled(Link, {
  textDecoration: 'underline',
  color: mineShaft,
});

const ViewOrder = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  flexBasis: '33%',
  [minMediumMediaQuery]: {
    flexBasis: '16%',
  },
});

const ViewOrderText = styled.div({
  marginBottom: pixelsToUnit(28),
  marginTop: pixelsToUnit(22),
  textDecoration: 'underline',
  [minMediumMediaQuery]: {
    margin: {
      y: 0,
      right: pixelsToUnit(12),
    },
  },
});

const ArrowPosition = styled(Down, {
  ...transition({
    property: 'transform',
    duration: 200,
  }),
  height: pixelsToUnit(8),
  width: pixelsToUnit(14),
});

const OrderDetailWrapper = styled.div({
  paddingBottom: 0,
  overflowY: 'hidden',
});

const MobileHeader = styled.div({
  marginTop: pixelsToUnit(28),
  overflowY: 'hidden',
});
