import React from 'react';
import { styled } from '@glitz/react';
import Image, { Ratio as ImageRatio } from 'Shared/Image/Ratio';
import { pixelsToUnit, minMediumMediaQuery, white, tundora, whiteLilacFaded } from 'Shared/Style';
import OrderLineItemViewModel from 'Order/Details/OrderLineItemViewModel.type';
import { translate } from '@avensia/scope';

type PropType = {
  products: OrderLineItemViewModel[];
  orderStatusDisplay: string;
};

export default (props: PropType) => {
  const { products, orderStatusDisplay } = props;
  return (
    <ProductsList>
      <Status>
        <strong>{`${translate('/Account/MyPages/OrderHistory/Status')}: `}</strong>
        {orderStatusDisplay}
      </Status>
      <ProductWrapper>
        {!!products &&
          products.length > 0 &&
          products.map((item: OrderLineItemViewModel, idx: number) => (
            <Product key={idx}>
              <CustomImage
                css={item.imageUrl && { backgroundColor: white }}
                src={item.imageUrl}
                ratio={ImageRatio.OneToOne}
              />
              {!!item && (
                <DetailsContainer>
                  <div>{item.productName}</div>
                  {item.returnQuantity > 0 && (
                    <div>
                      {`${translate('/Account/MyPages/OrderHistory/Status')}: `}
                      {translate('/Account/MyPages/OrderHistory/Returned')}
                    </div>
                  )}
                </DetailsContainer>
              )}
            </Product>
          ))}
      </ProductWrapper>
    </ProductsList>
  );
};

const Status = styled.div({
  marginBottom: pixelsToUnit(30),
});

const ProductsList = styled.div({
  backgroundColor: whiteLilacFaded,
  color: tundora,
  minHeight: pixelsToUnit(235),
  padding: {
    xy: pixelsToUnit(20),
  },
});

const ProductWrapper = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
});

const Product = styled.div({
  display: 'flex',
  marginBottom: pixelsToUnit(15),
  maxHeight: pixelsToUnit(100),
  width: '100%',
  [minMediumMediaQuery]: {
    width: '33%',
  },
});

const CustomImage = styled(Image, {
  width: pixelsToUnit(100),
  [minMediumMediaQuery]: {
    width: '30%',
  },
});

const DetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  paddingLeft: pixelsToUnit(20),
  maxWidth: '65%',
});
