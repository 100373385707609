/**
 * @ComponentFor OrderHistoryPageViewModel
 */

import * as React from 'react';
import { styled } from '@glitz/react';
import { Breakpoint, translate, URLX, getJson, pathCombine, currentUrl } from '@avensia/scope';
import connect from 'Shared/connect';
import OrderHistoryPageViewModel from './OrderHistoryPageViewModel.type';
import MyPagesBaseLayout from '../MyPagesBaseLayout';
import { lightGrey, pixelsToUnit, huge, minMediumMediaQuery } from 'Shared/Style';
import OrderList from './components/OrderList';
import H1 from 'Shared/Generic/h1';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import OrderDetailsViewModel from 'Order/Details/OrderDetailsViewModel.type';
import { bindInfiniteScroll } from 'Shared/infinite-scroll';
import Spinner from 'Shared/Icon/Spinner';
import { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';

type ConnectStateType = {
  currentBreakpoint: number;
  culture: string;
};

type StateType = {
  page: number;
  orders: OrderDetailsViewModel[];
  infinityScroll: boolean;
  currentlyReloading: boolean;
};

type PropType = OrderHistoryPageViewModel & ConnectStateType & ConnectPropType;
const GETORDERS_URL = '/getorders';

class OrderHistoryPage extends React.Component<PropType, StateType> {
  unbindInfiniteScroll: () => void;
  MyOrdersList: HTMLDivElement;
  constructor(props: PropType) {
    super(props);
    this.state = {
      page: 1,
      orders: props.orders,
      infinityScroll: false,
      currentlyReloading: false,
    };
  }
  componentWillUnmount() {
    this.deactivateInfinityScroll();
  }
  componentDidUpdate({}, prevstate: StateType) {
    if (this.state.currentlyReloading && !prevstate.currentlyReloading) {
      this.getMoreOrders();
    }
  }
  deactivateInfinityScroll() {
    if (this.state.infinityScroll) {
      this.setState({ infinityScroll: false });
    }
    if (this.unbindInfiniteScroll) {
      this.unbindInfiniteScroll();
    }
  }

  activateInfiniteScroll = () => {
    this.loadSpinner();
    this.setState({ infinityScroll: true });
    this.unbindInfiniteScroll = bindInfiniteScroll(this.MyOrdersList, this.loadSpinner, -500);
  };

  loadSpinner = () => {
    if (!this.state.currentlyReloading) {
      if (this.hasMore()) {
        this.setState({
          currentlyReloading: true,
        });
      } else {
        this.deactivateInfinityScroll();
      }
    }
  };

  getMoreOrders = async () => {
    const ordersUrl = new URLX(pathCombine(currentUrl().pathname, GETORDERS_URL));
    ordersUrl.searchParams.set('page', `${this.state.page + 1}`);
    try {
      const data = await getJson(ordersUrl);
      const allData = [...this.state.orders, ...data];
      this.updateOrders(allData);
    } catch (e) {
      console.warn('There was a problem fetching more orders');
      this.setState({
        currentlyReloading: false,
      });
    }
  };

  updateOrders = (orders: OrderDetailsViewModel[]) => {
    this.setState({
      orders,
      page: this.state.page + 1,
      currentlyReloading: false,
    });
  };

  hasMore = () => {
    return this.props.totalRecords > this.state.orders.length;
  };

  render() {
    const isMobile = this.props.currentBreakpoint < Breakpoint.Medium;
    const { orders, infinityScroll, currentlyReloading } = this.state;
    const FeedbackButton = this.props.feedback.Button;

    return (
      <MyPagesBaseLayout {...this.props}>
        <HeaderWrapper>
          <Header>{translate('/Account/MyPages/OrderHistory/Ordering')}</Header>
          {!isMobile && (
            <>
              <Header>{translate('/Account/MyPages/OrderHistory/AddedIt')}</Header>
              <Header>{translate('/Account/MyPages/OrderHistory/Sum')}</Header>
              <Header>{translate('/Account/MyPages/OrderHistory/PaymentStatus')}</Header>
              <Header>{translate('/Account/MyPages/OrderHistory/OrderStatus')}</Header>
              <Header />
            </>
          )}
        </HeaderWrapper>
        <div ref={el => (this.MyOrdersList = el)}>
          {!!orders && orders.length > 0 ? (
            orders.map((o, idx) => <OrderList key={idx} order={o} isMobile={isMobile} culture={this.props.culture} />)
          ) : (
            <NoOrders>{translate('/Account/MyPages/OrderHistory/NoOrders')}</NoOrders>
          )}
        </div>
        <Pagination>
          {!currentlyReloading && !infinityScroll && this.hasMore() && (
            <FeedbackButton
              css={{
                padding: {
                  x: huge,
                },
              }}
              variant={ButtonVariant.Large}
              appearance={ButtonAppearance.Secondary}
              onClick={this.activateInfiniteScroll}
            >
              {translate('/ProductListing/ShowMore')}
            </FeedbackButton>
          )}
          {currentlyReloading && <LoadingSpinner />}
        </Pagination>
      </MyPagesBaseLayout>
    );
  }
}

export default connectWithFeedback({ maximumFulfilled: 0 })(
  connect(state => ({
    currentBreakpoint: state.currentBreakpoint,
    culture: state.appShellData.culture,
  }))(OrderHistoryPage),
);

const HeaderWrapper = styled.div({
  borderBottom: {
    color: lightGrey,
    style: 'solid',
    width: pixelsToUnit(1),
  },
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: pixelsToUnit(46),
  paddingBottom: pixelsToUnit(11),
});

const Header = styled.div({
  flexBasis: '16%',
  fontWeight: 'bold',
});

const NoOrders = styled(H1, {
  marginTop: pixelsToUnit(70),
  textAlign: 'center',
});

const Pagination = styled.div({
  display: 'block',
  textAlign: 'center',
  margin: {
    y: pixelsToUnit(40),
  },
});

const LoadingSpinner = styled(Spinner, {
  display: 'flex',
  alignItems: 'center',

  margin: {
    x: 'auto',
    y: pixelsToUnit(30),
  },
  [minMediumMediaQuery]: {
    margin: {
      y: pixelsToUnit(50),
    },
    height: pixelsToUnit(35),
    width: pixelsToUnit(35),
  },
});
